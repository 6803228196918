.services{
  padding: 0 3rem 0 3rem;
  display: flex ;  
  height: 90vh;
  margin-bottom: 13rem;
  margin-top: 9rem;
}
.awesome{
    display: flex;
    flex-direction: column;
    position: relative;
    
}
.awesome > :nth-child(1){
  color: var(--black);
  font-size: 2.5rem;
  font-weight: bold;
}
.awesome > :nth-child(2){
 
color: var(--orange);
font-size: 2.5rem;
font-weight: bold;
}
.awesome > :nth-child(3){
color: var(--gray);
font-size: 14px;
margin: .1rem;
}
.s-button{
  width: 8rem;
  height: 2rem;
  margin-top: 2rem;
}
.cards{
  position: relative;
}
.cards>*{
  position: absolute;
}
.s-blur2{
  left: 14rem;
  top: 8rem;
}
.s-blur1{
  top: 13rem;
  left: -18rem;
}

@media screen and (max-width:480px) {
  .services{
    margin-top: 0;
    flex-direction: column;
    gap: 5rem;
    height: 66rem;
    padding: 0;
  }
  .cards{
    display: flex;
    flex-direction: column;
    gap:17rem;
  }
  .cards>*{
    position: static;
  }

  
}